<template>
    <div class="page-digital-comms-template-advanced">
        <div class="row">
            <div class="col-6 col-mob-12 box">
                <div class="board">
                    <h3 class="heading">Replies</h3>
                    
                    <app-loader v-if="processing.replies"></app-loader>

                    <app-error v-model="errors.replies.save"></app-error>

                    <app-dropdown-select
                        v-model="current_obj.replies.method"
                        :options="replies_methods"

                        label="Reply Method:"
                    ></app-dropdown-select>

                    <app-input      v-if="current_obj.replies.method == 'EMAIL'"  v-model="current_obj.replies.RepliesEmailDestination" label="Destination" type="text" :required="true" :error="errors.replies.RepliesEmailDestination" @change="errors.replies.RepliesEmailDestination = null" :disabled="processing.replies"></app-input>
                    <app-input v-else-if="current_obj.replies.method == 'SMS'"    v-model="current_obj.replies.RepliesSMSDestination"   label="Destination" type="tel"  :required="true" :error="errors.replies.RepliesSMSDestination"   @change="errors.replies.RepliesSMSDestination = null"   :disabled="processing.replies"></app-input>
                    <template  v-else-if="current_obj.replies.method == 'WEBHOOK'">
                        <app-input                                                v-model="current_obj.replies.RepliesWebhookURL"       label="URL"         type="text" :required="true" :error="errors.replies.RepliesWebhookURL"       @change="errors.replies.RepliesWebhookURL = null"       :disabled="processing.replies"></app-input>
                        <pre>{{ example }}</pre>
                    </template>

                    <button class="btn btn-small btn-primary" @click="submit('replies')" :disabled="processing.replies || !is_changed_replies">Submit</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'

import appInput from '@/components/app-input'
import appDropdownSelect from '@/components/app-dropdown-select'

import DigitalCommsTemplatesValidator from '@/validators/digital-comms-templates-validator'

export default {
    components: {
        appLoader,
        appError,

        appInput,
        appDropdownSelect,
    },

    data() {
        return {
            template: {},

            current_obj: {
                replies: {
                    method: null,
                    RepliesEmailDestination: null,
                    RepliesSMSDestination: null,
                    RepliesWebhookURL: null,
                },
            },
            original_obj: {
                replies: {},
            },

            replies_methods: [
                { id: 'EMAIL',   name: 'E-Mail'  },
                { id: 'SMS',     name: 'SMS'     },
                { id: 'WEBHOOK', name: 'Webhook' },
            ],

            errors: {
                replies: {},
            },

            processing: {
                replies: false,
            },
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            return this.$store.dispatch('api_notification/getCachedNotificationTemplateByUUID', { SPID: this.current_spid, UUID: this.uuid }).then((template) => {
                this.template = template

                this.current_obj.replies = {
                    method: 'SMS',
                    RepliesEmailDestination: 'lorem@iqhive.com',
                    RepliesSMSDestination: '02123456789',
                    RepliesWebhookURL: '/webhooks/lorem-ipsum',
                }
                this.original_obj.replies = {...this.current_obj.replies}
            }).catch(error => {
                console.log('error api_notification/getCachedNotificationTemplateByUUID', error)
            })
        },

        submit(target) {
            if (this.validation(target)) {
                this.processing[target] = true

                setTimeout(() => {
                    this.original_obj.replies = {...this.current_obj.replies}

                    this.processing[target] = false
                }, 500)
            }
        },

        validation(target) {
            let is_valid = true

            let values = {}

            switch (target) {
                case 'replies': {
                    switch (this.current_obj.replies.method) {
                        case 'EMAIL': {
                            values = {
                                RepliesEmailDestination: this.current_obj.replies.RepliesEmailDestination
                            }
                        } break

                        case 'SMS': {
                            values = {
                                RepliesSMSDestination: this.current_obj.replies.RepliesSMSDestination
                            }
                        } break

                        case 'WEBHOOK': {
                            values = {
                                RepliesWebhookURL: this.current_obj.replies.RepliesWebhookURL
                            }
                        } break
                    }
                } break
            }

            this.errors[target] = {}

            for (const field in values) {
                if (DigitalCommsTemplatesValidator.isRuleExists(field)) {
                    const error = DigitalCommsTemplatesValidator.isInvalid(field, values[field])

                    if (error) {
                        this.$set(this.errors[target], field, error)
                        is_valid = false
                    }
                }
            }

            return is_valid
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
        ]),

        uuid() {
            return this.$route.params.uuid
        },

        is_changed_replies() {
            return this.current_obj.replies.method != this.original_obj.replies.method
                || this.current_obj.replies.RepliesEmailDestination != this.original_obj.replies.RepliesEmailDestination
                || this.current_obj.replies.RepliesSMSDestination != this.original_obj.replies.RepliesSMSDestination
                || this.current_obj.replies.RepliesWebhookURL != this.original_obj.replies.RepliesWebhookURL
        },

        example() {
            return {
                TemplateID: this.template.TemplateID,
                Source: 'a@b.com',
                Destination: 'x@y.com',
                Body: 'Yes, please process my upgrade',
            }
        },
    },
}
</script>

<style lang="scss">
.page-digital-comms-template-advanced {
    .row {
        .box {
            margin-bottom: 24px;
        }
    }

    .board {
        padding: 24px;
        border-radius: $border-radius-secondary;
        background: var(--color-component-bg-primary);
        box-shadow: var(--box-shadow-primary);
        position: relative;

        .app-error {
            margin-top: 16px;
        }

        .app-input,
        .app-dropdown-select,
        pre {
            margin: 24px 0 0;
        }

        &>.btn {
            margin-top: 32px;
        }

        pre {
            color: var(--color-pre);
            padding: 8px 16px;
            border-radius: $border-radius-primary;
            background: var(--color-pre-bg);
        }
    }
}

@media (max-width: $tablet-size) {
    .page-digital-comms-template-advanced {
        .board {
            padding: 16px;

            &>.btn {
                margin-top: 32px;
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .page-digital-comms-template-advanced {
        .board {
            padding: 16px 8px 8px;

            &>.btn {
                max-width: 100%;
            }
        }
    }
}
</style>